<template>
  <div id="AppointmentCard">
    <div class="apptCard mx-auto pt-4 px-3 pb-2">
      <div class="topRow">
        <div class="imageColumn">
          <img :src="item.featured_image" :alt="item.title" v-if="item.featured_image"/>
        </div>
        <div class="textColumn px-3">
          <div class="title">
            {{item.position ? item.position : item.title}}
          </div>
          <div class="name">
            <span v-if="item.position">{{item.title}}</span>
          </div>
          <div class="time">
            <i class="fa-solid fa-clock"></i>
            {{appointmentTime}}
          </div>
        </div>
        <div class="dateColumn">
          <div class="day">{{this.appointmentDay}}</div>
          <div class="month">{{this.appointmentMonth}}</div>
        </div>
      </div>
      <div v-if="upcoming" class="pt-2">
        <div v-if="cancelMode" class="areYouSure">Are you sure you wish to cancel?</div>
        <div v-if="!cancelMode" class="btnRow">
          <div class="joinBtn cardBtn" @click="goToAppointment">
            <i class="fa-solid fa-video"></i>
            Join
          </div>
          <div class="cancelBtn cardBtn" v-if="canCancel" @click="cancelMode = true">
            Cancel
          </div>
        </div>
        <div v-else class="cancelMode btnRow">
          <div class="cardBtn cancelBtn position-relative" @click="cancel">
            <span v-if="cancellationInProgress" class="button-loading"></span>
            <span v-else>Yes, cancel</span></div>
          <div class="cardBtn greenBtn" @click="cancelMode = false">Go back</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Eventbus from "../helpers/eventbus";
export default {
  props: ['item','upcoming'],
  name: "AppointmentCard",
  data() {
    return {
      cancelMode: false,
      cancellationInProgress: false
    }
  },
  computed: {
    canCancel() {
      if(this.item.type === 'dg' || this.item.type === 'corporate' || this.item.type === 'ws') return true;
      if(this.item.type === 'dc') return false;
      return false;
    },
    appointmentTime() {
      const startMoment = moment(this.item.start);
      const endMoment = moment(this.item.end);
      const formattedStartTime = startMoment.format('HH:mm');
      const formattedEndTime = endMoment.format('HH:mm');
      return `${formattedStartTime} - ${formattedEndTime}`
    },
    appointmentDay() {
      const startMoment = moment(this.item.start);
      return startMoment.format('DD');
    },
    appointmentMonth() {
      const startMoment = moment(this.item.start);
      return startMoment.format('MMM');
    }
  },
  methods: {
    goToAppointment(){
      switch(this.item.type){
        case 'dg':
          this.$router.push('/digital-gym/' + this.item.id + '?back=home')
          break;
        case 'dc':
          if(this.item.is_cmh) {
            this.$router.push('/service-dashboard/'+this.item.slug);
          } else {
            this.$router.push('/clinics/digital');
          }
          break;
        case 'tmh':
          this.$router.push('/total-mental-health');
          break;
        case 'corporate':
          this.$router.push('marketplace/events/view-event/'+ this.item.event_date_id + '?back=home');
          break;
        case 'ws':
          this.$router.push('/wellbeing-studio/' + this.item.id + '?back=home');
          break;
      }
      Eventbus.$emit('closeSidebar');
      this.$emit('close');
    },
    async cancel() {
      this.cancellationInProgress = true;
      let path;
      let payload = {
        user_id: this.$store.getters.user_id
      };
      switch (this.item.type) {
        case "dg":
          path = "/application/api/u/studio/cancel-reminder";
          payload.event_id = this.item.id;
          break;
        case "ws":
          path = "/application/api/u/studio/cancel-reminder";
          payload.event_id = this.item.id;
          break;
        case "corporate":
          path = "/application/api/u/events/cancel-date-booking";
          payload.event_date_id = this.item.event_date_id;
          payload.all_series = false;
          break;
        case "dc":
          path = '/application/api/u/digital-bookings/cancel-booking';
          payload.booking_id = this.item.id;
          break;
      }
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          path,
          payload
      );
      if(res.data.success) {
        this.cancellationInProgress = false;
        Eventbus.$emit('updateDFDReminders');
      }
    },
  }
}
</script>
<style lang="less">
#AppointmentCard {
  margin-top: 10px;
  color: white;
  .apptCard {
    width: 90%;
    background-color: #3A4A61;
    border: 1px solid #D7D7D7;
    border-radius: 10px;
    display: grid;
    grid-template-rows: fit-content(100%) fit-content(100%);
    .topRow {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      .textColumn {
        .title {
          font-size: 1.75rem;
        }
        .name {
          font-size: 1.35rem;
        }
        .time {
          font-size: 1.35rem;
          i {
            margin-right: 10px;
          }
        }
      }
      .imageColumn {
        display: flex;
        align-items: start;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          display: block;
        }
      }
      .dateColumn {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .day {
          font-size: 3.2rem;
        }
        .month {
          font-size: 1.6rem;
        }
      }
    }
    .areYouSure {
      font-size: 1.3rem;
      margin-bottom: 5px;
    }
    .btnRow {
      display: flex;
      align-items: end;
      .cardBtn {
        width: 134px;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 1.4rem;
        cursor: pointer;
        margin-right: 5px;
      }
      .joinBtn {
        color: white;
        background: #604BFF;
        i {
          margin-right: 10px;
        }
      }
      .cancelBtn {
        background-color: #DC3412;
      }
      .greenBtn{
        background: #30DE7A;
      }
    }
    .button-loading::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-radius: 50%;
      border-top-color: white;
      border-bottom-color: white;
      animation: button-loading-spinner 1s ease infinite;
    }
  }
}
</style>