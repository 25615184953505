<template>
  <div id="CompanyBoardModal">
    <div class="row">
      <div class="col-md-6">
        <div class="mb-1">
          <b-form-text id="">
            Title
          </b-form-text>
          <b-form-input
              id="input-room"
              v-model="post.title"
              aria-describedby="input-room-help input-live-help input-live-feedback"
              placeholder="Page Title"
              trim
          ></b-form-input>
        </div>
        <div class="mb-1">
          <b-form-text id="">
            Image
          </b-form-text>
          <div v-if="file_errors.length > 0">
            <span v-html="r" class="small text-danger" v-for="(r, i) in file_errors" :key="i"></span>
          </div>
          <div v-else class="">
            <div class="image-preview text-center" v-if="file.length > 0">
              <img class="img-fluid" :src="file">
            </div>
            <div v-else class="image-preview text-center">
              <img class="img-fluid" :src="post.image_url">
            </div>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <input type="file" id="imgInp" ref="file" style="display: none;" @change="handleUpload" accept="image/jpg,image/png,image/jpeg">
              <label class="file-upload-label btn" for="imgInp">
                Select Image
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div>
          <b-form-text id="">
            Category
          </b-form-text>
          <b-form-select id="cat-select" class="rounded form-control" v-model="post.categories" :select-size="this.savedCategories.length" multiple >
            <b-form-select-option
                v-for="cat in savedCategories" :value="cat.id"
                :key="'cat-'+cat.id"
                >{{cat.title}}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div>
          <b-form-text>
            Tag
          </b-form-text>
          <b-form-input list="modal-data-tags" v-model="post.tag"/>
          <datalist id="modal-data-tags">
            <option v-for="tag in tags" :value="tag"/>
          </datalist>
        </div>
        <div class="row switch-holder">
          <div class="d-flex align-items-center col-md-6 justify-content-around">
            <div style="margin-top: 0" class="switch-text">Featured: </div>
            <b-form-checkbox class="big-checkbox" v-model="post.featured" name="check-button" switch value="1" unchecked-value="0" size="lg"/>
          </div>
          <div class="d-flex align-items-center col-md-6 justify-content-around">
            <div style="margin-top: 0" class="switch-text">Published: </div>
            <b-form-checkbox class="big-checkbox" v-model="post.published" name="check-button" switch value="1" unchecked-value="0" size="lg"/>
          </div>
        </div>
        <div class="row" v-if="!nav_type_standard">
          <div class="col-md-4">
            <b-form-text id="">
              Post/Resource
            </b-form-text>
            <b-form-select v-model="post.post_type" :options="post_type_options"/>
          </div>
          <div class="col-md-4">
            <b-form-text id="">
              Format
            </b-form-text>
            <b-form-select v-model="post.format" :options="format_options" :disabled="post.post_type === 'post'"/>
          </div>
          <div class="col-md-4">
            <b-form-text id="">
              Order
            </b-form-text>
            <b-form-input type="number" v-model="post.rank"/>
          </div>
        </div>
        <div v-if="!nav_type_standard && post.post_type === 'resource' && post.format !== 'text'">
          <b-form-text id="">
            Asset URL
          </b-form-text>
          <b-form-input v-model="post.asset_url"/>
        </div>
        <div>
        <b-form-text id="">
          Excerpt
        </b-form-text>
        <textarea placeholder="Type excerpt here..."
                  id="site-specific-info"
                  rows="7"
                  v-model="post.excerpt"
                  class="form-control">
        </textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <br/>
        <b-form-text id="">
          Content
        </b-form-text>
        <vue-editor v-model="post.content" :editor-toolbar="customToolbar" />
      </div>
    </div>
    <div class="row mt-3 d-flex justify-content-end pr-3">
      <b-button class="btn btn-purple btn-sm" @click="saveChanges" :disabled="!allEntriesValid">Save Post</b-button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "CompanyBoardModal",
  components: {
    VueEditor
  },
  props: ['posts','categories','postToEditIndex','nav_type_standard','post_type_options','tags'],
  data() {
    return {
      post: {},
      default_post: {
        id: null,
        categories: [],
        title: '',
        image_url: "",
        excerpt: '',
        content: '',
        featured: 0,
        published: 0,
        format: 'text',
        post_type: 'post',
        asset_url: "",
        rank: ""
      },
      file: {},
      format_options: ['text','audio','video','image'],
      file_errors: [],
      binary_file: false,
      loaded_img: {},
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link'],
      ]
    }
  },
  computed: {
    allEntriesValid() {
      return this.post.title.length > 3
          && this.post.categories.length >= 1;
    },
    savedCategories() {
      return this.categories.filter(cat => !isNaN(cat.id));
    }
  },
  inject: ['getData'],
  methods: {
    setPost() {
      if(this.postToEditIndex === null) {
        this.post = this.default_post;
        this.post.id = 'NEW-POST'
      } else {
        //JSON below prevents binding
        this.post = JSON.parse(JSON.stringify(this.posts[this.postToEditIndex]));
        if(this.post.rank == 9999) this.post.rank = ""
      }
    },
    saveChanges() {
      let formData = new FormData();
      if(this.binary_file) {
        formData.append('file', this.binary_file);
      }
      formData.append('user_id', this.$store.getters.user.user_token);
      for(const [key,value] of Object.entries(this.post)) {
        if(key === 'content' || key === 'excerpt') {
          formData.append(key,btoa(unescape(encodeURIComponent(value))));
        } else if (key === 'id') {
          formData.append('post_id',value);
        } else if (key === 'rank' && !value) {
          formData.append('rank','9999');
        } else if (key === 'categories') {
          formData.append('categoryIds',JSON.stringify(value));
        } else if (key === 'tag') {
          formData.append(key,value ? value: '');
        } else {
          formData.append(key,value);
        }
      }
      let url = process.env.VUE_APP_API_PATH;
      let text = 'Post successfully ';
      if(isNaN(this.post.id) && this.post.id.includes('NEW')) {
          url += '/application/api/c/company-board/create-post';
          text += 'created';
        } else {
          url += '/application/api/c/company-board/update-post';
          text += 'updated';
        }
        const opts = {
          method: 'post',
          body: formData
        }
        fetch(url, opts).then(response => {
              response.json().then(data => {
                if(data.success == true){
                  this.$store.commit('setAlert', {
                    visible : true,
                    text: text,
                    color: 'success'
                  });
                  this.$emit('saved');
                  this.$bvModal.hide('edit-post-modal')
                } else {
                  this.$store.commit('setAlert', {
                    visible : true,
                    text: data.message,
                    color: 'danger'
                  });
                }
              });
            }
        )
    },
    handleUpload: function(event) {
      // Reference to the DOM input element
      let file_errors = [];
      this.file_errors = file_errors;
      let input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        let img = new Image();
        this.binary_file = input.files[0];
        img.src = window.URL.createObjectURL( input.files[0] );
        window.URL.revokeObjectURL(img.src);
        // Start the reader job - read file as a data url (base64 format)
        if(file_errors.length === 0){
          let reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Read image as base64 and set to file
            if(e.target.result.size > 5120000){
              file_errors.push('File size should less then 5MB.');
            } else {
              this.file = e.target.result;
            }
          }
          reader.readAsDataURL(input.files[0]);
        }
        this.file_errors = file_errors;
      }
    },
  },
  watch: {
    'post.post_type': {
      handler(newVal) {
        if(newVal === 'post') this.post.format = 'text';
      }
    },
    postToEditIndex: {
      immediate: true,
      handler() {
        this.setPost();
      }
    }
  }
}
</script>

<style lang="less">
  #CompanyBoardModal {
    small, input, textarea, .switch-text {
      font-size: 1.5rem;
    }
    #cat-select {
      font-size: 1.4rem;
      overflow: hidden !important;
    }
    .switch-holder{
      margin: 20px 0px;
      border: 1px solid #ced4da;
      padding: 12px;
      border-radius: 5px;
      .switch-text {
        font-family: "DMSans";
        color: #495057
      }
    }
    .modal-title {
      font-size: 1.35em;
      font-weight: bold;
    }
    .quillWrapper {
      border-radius: 5px;
    }
    .form-control {
      border-radius: 5px !important;
    }
    .file-upload-label {
      padding: 5px 20px;
      font-size: 0.8em;
      border: none !important;
      background-color: #E83B75;
      color: white;
      &:hover {
        background-color: #B41D55;
        cursor: pointer;
      }
    }
  }

</style>