<template>
    <div id="HomeFilters">
        <div class="container">
          <div ref="HomeFilters"></div>
        </div>
        <div v-if="loading" class="pb-3 container d-flex flex-row justify-space-between align-items-center">
          <HomeLoading/>
        </div>
        <div v-else>
          <div class="pb-3 container d-flex flex-row justify-space-between align-items-center">
              <div>
                  <h2 class="home-title">Filter</h2>
                  <div class="home-title-line bg-brand-secondary"></div>
              </div>

          </div>
          <div class="container my-3">
            <div class="row position-relative">
              <b-input class="filterSearchBar mx-auto brand-text" v-model="searchTerm" placeholder="Search..." />
              <i class="fa-solid fa-magnifying-glass position-absolute" style="top: 15px; right: 30px; font-size: 2.0rem" v-if="!searchTerm.length"></i>
              <span @click="searchTerm = ''" class="position-absolute brand-highlight-1" style="cursor: pointer; top: 15px; right: 30px; font-size: 1.5rem; font-family: FilsonProBold, Arial, Helvetica, sans-serif; text-decoration: underline" v-else>Clear Search</span>
            </div>

          </div>
          <div class="container mb-5">
              <div class="row">
                  <div class="tagContainer">
                      <span v-if="searchTerm" class="brand-text suggested">Suggested:</span>
                      <b-button :style="{fontSize: '1.5rem'}" class="mx-2 my-2 btn" :variant="selectedFilter.id === null ? 'cta' : 'cta-outline'" @click="clearFilter()" v-if="!searchTerm">All</b-button>
                      <b-button v-for="(tag,i) in tags_to_display" :style="{fontSize: getFontSize(tag.tag)}" :key="'tag'+i" class="mx-2 my-2 btn" :variant="selectedFilter.id === tag.id ? 'cta' : 'cta-outline'" @click="selectFilter(tag)">{{tag.tag}}</b-button>
                      <div v-if="filteredTags.length > unexpandedNumber" class="seeMoreBtn brand-highlight-1 m-2" @click="expanded = !expanded">
                        <div class="d-flex justify-content-center align-items-center">
                          {{expanded ? "...See Less" : "See More..."}}
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";

export default {
    name: "HomeFilters",
    components: {
      HomeLoading
    },
    data() {
        const tags = ["Family", "Parenting","Awareness","Female Health","Mental Health","Meditation","Mindfulness","Neurodiversity","Sleep","Stress","Work life Balance","Yoga","Pilates","Cardio"];
      if(this.$store.getters.skin === 30) {
        tags.unshift('Hospitality');
      }
        return {
            selectedFilter: {id: null, tag: 'All'},
            tags: [],
            excludedTags: ['Training Plans','Courses','Surge','Draft','Ignite','Healthcoach','Try One Of These'],
            hardCodedTags: tags,
            loading: true,
            expanded: false,
            unexpandedNumber: 10,
            searchTerm: "",
            displayWidth: 0
        };
    },
    mounted() {
        this.getTags();
        this.getContent();
    },
    destroyed() {
      window.removeEventListener('resize',this.getWidth)
    },
    created() {
      window.addEventListener('resize', this.getWidth);
    },
    computed: {
        filteredTags() {
          if(!this.searchTerm) return this.tags;
          return this.tags.filter(item => item.tag.toLowerCase().includes(this.searchTerm.toLowerCase()))
        },
        tags_to_display() {
          if(this.expanded) return this.filteredTags;
          const tags = this.filteredTags.slice(0,this.unexpandedNumber);
          if(this.selectedFilter.id) {
            let isSelectedFilterInTags = false;
            tags.forEach(t => {
              if(t.id === this.selectedFilter.id) isSelectedFilterInTags = true;
            })
            if(!isSelectedFilterInTags) {
              tags.pop();
              tags.push(this.selectedFilter)
            }
          }
          return tags;
        }
    },
    methods: {
        getWidth() {
          console.log('gettingWidth')
          this.displayWidth = this.$refs.HomeFilters.clientWidth;
          this.unexpandedNumber = Math.max(Math.floor(this.displayWidth / 160) - 2, 4);
        },
        getFontSize(text) {
          if(text.length > 17) return '1.1rem'
          if(text.length > 13) return '1.3rem'
          return '1.5rem';
        },
        async getTags(){
            let res = await this.api({
                path: "api/u/content/tags",
            });
            if (res.success) {
                this.tags = [];
                let t = Array.isArray(res.data) ? res.data : Object.values(res.data);
                const tags = this.hardCodedTags.concat(t)
                tags.forEach((tag,i) => {
                    if(!this.excludedTags.includes(tag)) {
                      let include = true;
                      this.tags.forEach(item => {
                        if(item.tag.toLowerCase() === tag.toLowerCase()) include = false
                      })
                      if(include) this.tags.push({id: i+1 , tag:tag})
                    }
                });
            }
            this.getWidth();
            this.loading = false;
        },
        clearFilter(){
            this.selectedFilter = {id: null, tag: 'All'}
            EventBus.$emit('setTag', null)
        },
        selectFilter(tag){
            this.selectedFilter = tag;
            EventBus.$emit('setTag', tag);
        },
    }
};
</script>

<style lang="less">
#HomeFilters{
  .btn, button {
      width: 150px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .filterSearchBar {
    all: unset;
    width: 98%;
    height: 40px;
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(211, 211, 211, 1);
    border-radius: 15px;
    padding: 5px 20px;
    font-size: 1.5rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif !important;

  }
  .seeMoreBtn {
    background-color: transparent;
    width: 140px;
    padding-right: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    text-align: right;
    height: 37px;
    display: inline-block;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    div {
      height: 100%;
      width: 100%;
    }
  }
  .suggested {
    padding: 10px 15px;
    font-size: 1.5rem;
    height: 35px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
  .tagContainer {
    width: 100%;
  }
}
</style>
