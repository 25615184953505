<template>
  <div id="RewardsLevels" class="h-100">
<!--    <div class="bg-grey pb-5" v-if="!$store.getters.user.access_code_user">-->
<!--      <div class="content-container row">-->
<!--        <div class="section-header section-header-right px-4">-->
<!--          <div class="section-header-divider"></div>-->
<!--          <h3 class="section-header-title">{{ componentText.yourLevel }}</h3>-->
<!--        </div>-->
<!--        <div class="col-12 col-md-6 pl-4 pl-md-5 pr-0 pr-md-4">-->
<!--          <div-->
<!--            v-for="level in levels"-->
<!--            class="levels-card row align-items-center mb-5 py-2 py-md-4"-->
<!--            :class="{ achieved: level.achieved }"-->
<!--            @click="viewLevel(level)"-->
<!--          >-->
<!--            <div class="col-2 col-md-4 level-icon">-->
<!--              <img :src="level.icon_url" />-->
<!--              <div class="locked">-->
<!--                <font-awesome-icon icon="lock"></font-awesome-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-9 col-md-7 brand-secondary level-info px-0">-->
<!--              <p>{{ level.title }}</p>-->
<!--              <p>-->
<!--                <span class="points"-->
<!--                  >{{ level.points_min }} - {{ level.points_max }}</span-->
<!--                >-->
<!--              </p>-->
<!--              <p>{{ componentText.points }}</p>-->
<!--            </div>-->
<!--            <div class="col-1">-->
<!--              <div class="chevron">-->
<!--                <font-awesome-icon icon="chevron-right"></font-awesome-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 col-md-6 pl-md-5 level-details d-none d-md-block">-->
<!--          <div-->
<!--            class="row level-details-card pl-5 pr-0 pb-5 ml-md-3 align-items-center bg-white"-->
<!--            :class="{ achieved: activeLevel.achieved }"-->
<!--          >-->
<!--            <div class="locked">-->
<!--              <font-awesome-icon icon="lock"></font-awesome-icon>-->
<!--            </div>-->
<!--            <div class="col-8">-->
<!--              <h3>{{ activeLevel.title }}</h3>-->
<!--            </div>-->
<!--            <div class="col-4 px-0 level-icon">-->
<!--              <img :src="activeLevel.icon_url" />-->
<!--            </div>-->
<!--            <div class="col-12 pb-3">-->
<!--              <p>{{ activeLevel.description }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div id="pop-up" v-if="showPopup" class="d-flex d-md-none px-3">
      <div class="pop-up-background" @click="hidePopup"></div>
      <div class="pop-up-content">
        <div
          class="row level-details-card pl-5 pr-0 pb-5 ml-md-3 align-items-center bg-white"
          :class="{ achieved: activeLevel.achieved }"
        >
          <div class="locked">
            <font-awesome-icon icon="lock"></font-awesome-icon>
          </div>
          <div class="col-8">
            <h3>{{ activeLevel.title }}</h3>
          </div>
          <div class="col-4 px-0 level-icon">
            <img :src="activeLevel.icon_url" />
          </div>
          <div class="col-12 pb-3 pr-0">
            <p>{{ activeLevel.description }}</p>
          </div>
        </div>
        <b-button class="close-popup" @click="hidePopup"
          ><font-awesome-icon icon="times-circle"></font-awesome-icon
        ></b-button>
      </div>
    </div>
    <div class="bg-white h-100">
      <div class="content-container row">
        <div class="section-header section-header px-4">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title">{{ clientIsEY ? 'Discounts to redeem' : componentText.redeem }}</h3>
        </div>
        <div class="row rewards-container">
          <div class="col-lg-4 col-md-12 rewards-item-container">
            <div
              class="rewards-item d-flex flex-row justify-content-around align-items-center"
            >
              <div>
                <img
                  class="rewards-item-image"
                  src="/img/reward/apple-icon.svg"
                />
              </div>
              <div>
                <h4 class="rewards-item-title">Apple&nbsp;Products</h4>
                <span class="rewards-item-percentage">3-10% off</span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 rewards-item-container">
            <div
              class="rewards-item d-flex flex-row justify-content-around align-items-center"
            >
              <div>
                <img
                  class="rewards-item-image"
                  src="/img/reward/buyagift.svg"
                />
              </div>
              <div>
                <h4 class="rewards-item-title">Buyagift.com</h4>
                <span class="rewards-item-percentage">10% off</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 rewards-item-container">
            <div
              class="rewards-item d-flex flex-row justify-content-around align-items-center"
            >
              <div>
                <img
                  class="rewards-item-image"
                  src="/img/reward/spabreaks.svg"
                />
              </div>
              <div>
                <h4 class="rewards-item-title">Spabreaks.com</h4>
                <span class="rewards-item-percentage">10% off</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container row">
          <p class="rewards-paragraph">
            The rewards listed above amongst hundreds of others are
            redeemable through our corporate BenefitHub.  To access these rewards click the link below.
          </p>
        </div>
        <b-button @click="getBenefitsUrl()" class="btn rewards-button cta-button px-5 py-4 mt-3 mb-5">Access BenefitHub</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
export default {
  name: "RewardsLevels",
  components: {},
  data() {
    return {
      levels: [],
      achievedLevel: "",
      activeLevel: {},
      rewards: [],
      showPopup: false,
    };
  },
  mounted() {
    this.getEngagementLevels();
  },
  methods: {
    submitSamlResponse(samlResponse, acsUrl) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = acsUrl;

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'SAMLResponse';
      input.value = samlResponse;

      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    },
    async getBenefitsUrl() {
      if(this.$store.getters.skin === 25 && this.$store.getters.user.access_code_user){
        window.location.href = 'https://spectrumbenefits.benefithub.com/';
      } else {
        const slPayload = {
          user_id: this.$store.getters.user_id,
        };
        let slRes = await axios.post(
          config.api_env + "/application/api/p/saml/sso",
          slPayload,
          config.options
        );
        
        this.submitSamlResponse(slRes.data.samlResponse, slRes.data.acsUrl);
      }
    },

    async getEngagementLevels() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-engagement-levels",
        payload,
        config.options
      );
      if (res.data.success) {
        this.levels = res.data.levels;
        this.achievedLevel = res.data.achieved_level_id;
        this.activeLevel = this.levels[this.achievedLevel - 1];
      }
    },
    viewLevel(level) {
      this.activeLevel = level;
      this.showPopup = true;
    },
    async getRewards(level) {
      const payload = {
        user_id: this.$store.getters.user_id,
        level: level,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/list-surge-rewards",
        payload,
        config.options
      );
      if (res.data.success) {
        this.rewards = res.data.rewards;
      }
    },
    viewReward(reward) {
      if (this.activeLevel.achieved) {
        this.$emit("view-reward", reward);
      }
    },
    hidePopup() {
      this.showPopup = false;
    },
  },
  watch: {
    activeLevel(newLevel) {
      this.getRewards(newLevel.level_id);
    },
  },
  computed: {
    clientIsEY() {
      const eyId = process.env.NODE_ENV === 'production' ? 255 : 6141;
      return Number.parseInt(this.$store.getters.user.user_client_id) === Number.parseInt(eyId);
    },
    componentText() {
      return this.getText.loggedIn.rewards.rewardslevels;
    },
  },
};
</script>
<style lang="less">
#RewardsLevels {
  .rewards-container {
    min-width: 100%;
    margin: auto;
  }
  .rewards-item-container {
    margin-bottom: 20px;
  }
  .rewards-item {
    border: 2px lightgrey solid;
    border-radius: 10px;
    padding: 40px 15px;
  }
  .rewards-paragraph {
    font-size: 2rem;
    padding: 40px 10px;
  }
  .rewards-item-title {
    font-size: 2.5rem;
    word-break: break-word;
    margin-bottom: 0px;
  }
  .rewards-item-image {
    margin: 10px;
    height: 100px;
    width: auto;
  }
  .rewards-item-percentage {
    font-size: 2rem;
    color: lightblue;
    font-weight: bold;
  }
  .rewards-button {
    margin-left: 10px;
    width: 300px !important;
    font-size: 1.4rem;
  }
  .levels-card {
    font-family: FilsonProLight;
    font-size: 2rem;
    background: #fff;
    position: relative;
    border-width: 0 1px 1px 10px;
    border-style: solid;
    border-color: #dcdcdc;
    overflow-x: visible;
    z-index: 10;
    filter: grayscale(100%);
    cursor: pointer;
    margin-right: 50px;
    &.achieved {
      filter: none;
      .locked {
        display: none;
      }
    }
    &:before {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 3px;
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      height: 45px;
      width: 45px;
      display: inline-block;
      border-right: 1px solid #dcdcdc;
      border-top: 1px solid #dcdcdc;
      box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.1);
      transform: skew(40deg) translateX(50%);
      background-color: #fff;
      z-index: 1;
    }
    &:after {
      border-top-right-radius: 13px;
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      height: calc(100% - 38px);
      width: 43px;
      border-right: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      background-color: #fff;
      transform: translateX(100%);
      box-shadow: 3px 3px 10px -3px rgba(0, 0, 0, 0.1);
      border-bottom-right-radius: 15px;
      z-index: 0;
    }
    p {
      margin-bottom: 0;
    }
    .level-icon {
      img {
        max-width: 120px;
        width: 100%;
      }
      .locked {
        position: absolute;
        font-size: 1.6rem;
        top: -15px;
      }
    }
    .level-info {
      position: relative;
      .points {
        font-family: FilsonProBold;
        font-size: 3.4rem;
      }
    }
  }
  .level-details-card {
    box-shadow: 6px -2px 8px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    border-style: solid;
    border-width: 0 0 0 10px;
    border-color: #dcdcdc;
    filter: grayscale(100%);
    font-family: DMSans, sans-serif;
    color: #3a4a61;
    font-size: 17px;
    letter-spacing: 0;
    margin-right: 80px;
    &.achieved {
      filter: none;
      .locked {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      height: 80px;
      width: 80px;
      display: inline-block;
      border-right: 1px solid #dcdcdc;
      border-top: 1px solid #dcdcdc;
      transform: skew(50deg) translateX(65%);
      background-color: #fff;
      z-index: 1;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 10px;
      box-shadow: 9px 0 5px -4px rgba(0, 0, 0, 0.1);
    }
    &:after {
      content: "";
      position: absolute;
      right: 1px;
      bottom: -1px;
      height: calc(100% - 74px);
      width: 85px;
      border-right: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      background-color: #fff;
      transform: translateX(100%);
      border-bottom-right-radius: 55px;
      border-top-right-radius: 0px;
      box-shadow: 4px -4px 5px 0 rgba(0, 0, 0, 0.1);
    }
    h3 {
      font-size: 4rem;
    }
    p {
      color: #394961;
    }
    .level-icon {
      img {
        width: 100%;
      }
      position: absolute;
      top: 50px;
      right: -50px;
      z-index: 15;
    }
    .locked {
      position: absolute;
      top: 20px;
      left: 50px;
    }
  }
  .reward-card {
    cursor: pointer;
    border-radius: 20px;
    background: #f6f6f6;
    font-size: 1.6rem;
    filter: grayscale(100%);
    &.achieved {
      filter: none;
      .locked {
        display: none;
      }
    }
    &:hover {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    }
    .reward-logo {
      border-radius: 20px;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        max-height: 74px;
      }
    }
    h5 {
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.7rem;
    }
    .locked {
      position: absolute;
      right: 10px;
      top: 5px;
    }
    p {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 992px) {
    .level-details {
      .level-details-card {
        h3 {
          font-size: 3rem;
        }
        .level-icon {
          position: absolute;
          top: 50px;
          right: -50px;
          z-index: 15;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .levels-card {
      .level-icon {
        img {
          max-width: 65px;
        }
        .locked {
          top: -25px;
        }
      }
      .level-info {
        P {
          font-size: 1.7rem;
        }
        .points {
          font-size: 2.5rem;
        }
      }
    }
    .level-details-card {
      img {
        max-width: 100px;
      }
      h3 {
        font-size: 3rem;
      }
      p {
        text-align: left;
      }
    }
    .reward-card {
      display: flex;
      flex-direction: row;
      .reward-logo {
        display: flex;
        width: 95px;
      }
    }
  }
}
</style>
